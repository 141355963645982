import { match } from "path-to-regexp";

interface Params {
  tscid: string;
  scopeId: string;
  basketId: string;
}

export function getTscidFromUrl(url = window.location.pathname): string {
  const result = match<Partial<Params>>(
    "/foretag/mybusiness/:scopeId/bestall/:tscid/din-bestallning/:basketId"
  )(url);

  if (result) {
    return result.params.tscid as string;
  } else {
    throw Error("Error when matching url");
  }
}

export function getBasketIdFromUrl(url = window.location.pathname): string {
  const result = match<Partial<Params>>(
    "/foretag/mybusiness/:scopeId/bestall/:tscid/din-bestallning/:basketId"
  )(url);

  if (result) {
    return result.params.basketId as string;
  } else {
    throw Error("Error when matching url");
  }
}
